/* global $ */
import React, { useEffect, useState } from "react"; 
import { Link } from "react-router-dom";
import ScriptLoader from "./Scriptloader";

const Header = () => {
  const [scriptsLoaded] = ScriptLoader();
  const [isMenuOpen, setIsMenuOpen] = useState(false); // State to control menu visibility

  useEffect(() => {
    if (scriptsLoaded) {
      // Initialize Scrollspy after scripts are loaded
      const $body = $("body"); // Make sure jQuery is available
      $body.scrollspy({ target: ".navbar", offset: 50 });
    }
  }, [scriptsLoaded]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      {scriptsLoaded && (
        <header className="top-area">
          <div className="header-area">
            <nav className="navbar navbar-default bootsnav navbar-fixed dark no-background">
              <div className="container">
                <div className="navbar-header">
                  <button
                    type="button"
                    className="navbar-toggle"
                    onClick={toggleMenu}
                    aria-expanded={isMenuOpen}
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                  <Link to="/" className="navbar-brand">
                    Hari
                  </Link>
                </div>

                <div
                  className={`collapse navbar-collapse menu-ui-design ${isMenuOpen ? 'in' : ''}`}
                  id="navbar-menu"
                >
                  <ul className="nav navbar-nav navbar-right" data-in="fadeInDown" data-out="fadeOutUp">
                    <li className="smooth-menu active"></li>
                    <li className="smooth-menu">
                      <Link to="/" onClick={closeMenu}>Home</Link>
                    </li>
                    <li className="smooth-menu">
                      <Link to="/education" onClick={closeMenu}>Education</Link>
                    </li>
                    <li className="smooth-menu">
                      <Link to="/skills" onClick={closeMenu}>Skills</Link>
                    </li>
                    <li className="smooth-menu">
                      <Link to="/experience" onClick={closeMenu}>Experience</Link>
                    </li>
                    <li className="smooth-menu">
                      <Link to="/contact" onClick={closeMenu}>Contact</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
          <div className="clearfix"></div>
        </header>
      )}
    </>
  );
};

export default Header;
