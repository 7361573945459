import  { useEffect, useState } from "react";

const ScriptLoader = () => {
  const [scriptsLoaded, setScriptsLoaded] = useState(false);

  useEffect(() => {
    const loadScripts = async () => {
      const jQueryScript = document.createElement("script");
      jQueryScript.src = "https://code.jquery.com/jquery-3.6.0.min.js";
      jQueryScript.onload = () => {
        const bootstrapScript = document.createElement("script");
        bootstrapScript.src = "https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js";
        bootstrapScript.onload = () => setScriptsLoaded(true);
        document.body.appendChild(bootstrapScript);
      };
      document.body.appendChild(jQueryScript);
    };

    loadScripts();

    return () => {
      // Cleanup scripts if needed (remove from document)
    };
  }, []);

  return [scriptsLoaded];
};

export default ScriptLoader;
