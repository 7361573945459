import React from "react";

const Education=() =>{
    return(<>
    <section  className="education mt-95">
			<div className="section-heading text-center">
				<h2>education</h2>
			</div>
			<div className="container">
				<div className="education-horizontal-timeline">
					<div className="row">
						<div className="col-sm-4">
							<div className="single-horizontal-timeline">
								<div className="experience-time">
									<h2>2015 - 2018</h2>
									<h3>Bachelor <span>of </span> Computer Science</h3>
								</div>
								<div className="timeline-horizontal-border">
									<i className="fa fa-circle" aria-hidden="true"></i>
									<span className="single-timeline-horizontal"></span>
								</div>
								<div className="timeline">
									<div className="timeline-content">
										<h4 className="title">
											Mess College (Calicut University)
										</h4>
										<h5>Calicut,Kerala</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="single-horizontal-timeline">
								<div className="experience-time">
									<h2>2011 - 2013</h2>
									<h3>Higher Secondary School</h3>
								</div>
								<div className="timeline-horizontal-border">
									<i className="fa fa-circle" aria-hidden="true"></i>
									<span className="single-timeline-horizontal"></span>
								</div>
								<div className="timeline">
									<div className="timeline-content">
										<h4 className="title">
											St Joseph B.H.S.S
										</h4>
										<h5>Calicut,Kerala</h5>
									</div>
								</div>
							</div>
						</div>
						<div className="col-sm-4">
							<div className="single-horizontal-timeline">
								<div className="experience-time">
									<h2>2004  - 2011</h2>
									<h3>High School</h3>
								</div>
								<div className="timeline-horizontal-border">
									<i className="fa fa-circle" aria-hidden="true"></i>
									<span className="single-timeline-horizontal spacial-horizontal-line
									"></span>
								</div>
								<div className="timeline">
									<div className="timeline-content">
										<h4 className="title">
											Silver Hills
										</h4>
										<h5>Calicut,Kerala</h5>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</section>
    </>)

}

export default Education;