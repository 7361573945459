import React from "react";
import { Link } from "react-router-dom";



const Profile=() =>{
    return(<>
<section id="profiles" className="profiles mt-90">
			<div className="profiles-details">
				<div className="section-heading text-center">
					<h2>profiles</h2>
				</div>
				<div className="container">
					<div className="profiles-content">
						<div className="row">
							<div className="col-sm-3">
								<div className="single-profile">
									<div className="profile-txt">
										<Link to=""><i className="flaticon-themeforest"></i></Link>
										<div className="profile-icon-name">themeforest</div>
									</div>
									<div className="single-profile-overlay">
										<div className="profile-txt">
											<Link to=""><i className="flaticon-themeforest"></i></Link>
											<div className="profile-icon-name">themeforest</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="single-profile">
									<div className="profile-txt">
										<Link to=""><i className="flaticon-dribbble"></i></Link>
										<div className="profile-icon-name">dribbble</div>
									</div>
									<div className="single-profile-overlay">
										<div className="profile-txt">
											<Link to=""><i className="flaticon-dribbble"></i></Link>
											<div className="profile-icon-name">dribbble</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="single-profile">
									<div className="profile-txt">
										<Link to=""><i className="flaticon-behance-logo"></i></Link>
										<div className="profile-icon-name">behance</div>
									</div>
									<div className="single-profile-overlay">
										<div className="profile-txt">
											<Link to=""><i className="flaticon-behance-logo"></i></Link>
											<div className="profile-icon-name">behance</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="single-profile profile-no-border">
									<div className="profile-txt">
										<Link to=""><i className="flaticon-github-logo"></i></Link>
										<div className="profile-icon-name">github</div>
									</div>
									<div className="single-profile-overlay">
										<div className="profile-txt">
											<Link to=""><i className="flaticon-github-logo"></i></Link>
											<div className="profile-icon-name">github</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="profile-border"></div>
						<div className="row">
							<div className="col-sm-3">
								<div className="single-profile">
									<div className="profile-txt">
										<Link to=""><i className="flaticon-flickr-website-logo-silhouette"></i></Link>
										<div className="profile-icon-name">flickR</div>
									</div>
									<div className="single-profile-overlay">
										<div className="profile-txt">
											<Link to=""><i className="flaticon-flickr-website-logo-silhouette"></i></Link>
											<div className="profile-icon-name">flickR</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="single-profile">
									<div className="profile-txt">
										<Link to=""><i className="flaticon-smug"></i></Link>
										<div className="profile-icon-name">smungMung</div>
									</div>
									<div className="single-profile-overlay">
										<div className="profile-txt">
											<Link to=""><i className="flaticon-smug"></i></Link>
											<div className="profile-icon-name">smungMung</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="single-profile">
									<div className="profile-txt">
										<Link to=""><i className="flaticon-squarespace-logo"></i></Link>
										<div className="profile-icon-name">squareSpace</div>
									</div>
									<div className="single-profile-overlay">
										<div className="profile-txt">
											<Link to=""><i className="flaticon-squarespace-logo"></i></Link>
											<div className="profile-icon-name">squareSpace</div>
										</div>
									</div>
								</div>
							</div>
							<div className="col-sm-3">
								<div className="single-profile profile-no-border">
									<div className="profile-txt">
										<Link to=""><i className="flaticon-bitbucket-logotype-camera-lens-in-perspective"></i></Link>
										<div className="profile-icon-name">bitBucket</div>
									</div>
									<div className="single-profile-overlay">
										<div className="profile-txt">
											<Link to=""><i className="flaticon-bitbucket-logotype-camera-lens-in-perspective"></i></Link>
											<div className="profile-icon-name">bitBucket</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</section>
    </>)
}

export default Profile;