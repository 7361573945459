import React from "react";

const Experience=() =>{
    return(<>
    <section id="experience" className="experience mt-95">
			<div className="section-heading text-center">
				<h2>experience</h2>
			</div>
			<div className="container">
				<div className="experience-content">
						<div className="main-timeline">
							<ul>
								<li>
									<div className="single-timeline-box fix">
										<div className="row">
											<div className="col-md-5">
												<div className="experience-time text-right">
													<h2>2021 - Present</h2>
													<h3>Senior Software Engineer</h3>
												</div>
											</div>
											<div className="col-md-offset-1 col-md-5">
												<div className="timeline">
													<div className="timeline-content">
														<h4 className="title">
															<span><i className="fa fa-circle" aria-hidden="true"></i></span>
															In Time Tec
														</h4>
														<h5>Bangalore,Karnataka</h5>
														<ul className="ExpDesc">
															<li>Worked on US project (truckstop.com).</li>
															<li>Developed and maintained web applications using ASP.NET and .NET Core, resulting in improved system performance and user experience.</li>
															<li>Designed and implemented database schemas, stored procedures, and queries using T-SQL, ensuring data integrity and reliability.</li>
															<li>Participated in sprint planning, daily stand-ups, and retrospectives to coordinate tasks and prioritize project deliverables.</li>
															<li>Conducted code reviews and implemented best practices to ensure code quality, security, and scalability.</li>
															<li>Developed/Maintained automations to get data from Department of Transportation (US) sites and storing/using it according to project needs.</li>
															<li>Sorting Visualizer. (JavaScript).</li>
															<li>Fetched data from the database based on client requirements.</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</li>

								<li>
									<div className="single-timeline-box fix">
										<div className="row">
											<div className="col-md-5">
												<div className="timeline-content text-right">
													
													<h4 className="title">
															Sesame Technologies
														</h4>
														<h5>Calicut, Kerala</h5>
														<p className="description">
														I worked on several projects using PHP, CodeIgniter, and Laravel, where I developed dynamic web applications and improved my skills in back-end development and database management.
														</p>
												</div>
											</div>
											<div className="col-md-offset-1 col-md-5">
												<div className="timeline">
													<div className="experience-time">
															<span><i className="fa fa-circle" aria-hidden="true"></i></span>
															<h2>
																2019 - 2020
															</h2>
													<h3>Software Engineer</h3>
													</div>
												</div>
											</div>
										</div>
									</div>
								</li>
								<li>
									<div className="single-timeline-box fix">
										<div className="row">
											<div className="col-md-5">
												<div className="timeline">
													<div className="experience-time text-right">
													<h2>
														2018 - 2019
													</h2>
													<h3>Web developer</h3>
													</div>
												</div>
											</div>
											<div className="col-md-offset-1 col-md-5 ">
												<div	div className="timeline">
													<div className="timeline-content">
														<h4 className="title">
															<span><i className="fa fa-circle" aria-hidden="true"></i></span>
															Techieprise 
														</h4>
														<h5>Calicut,Kerala</h5>
														<p className="description">
														I worked on several projects using PHP, CodeIgniter, and Laravel, where I developed dynamic web applications and improved my skills in back-end development and database management.
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</li>

								<li>
									<div className="single-timeline-box fix">
										<div className="row">
											<div className="col-md-5">
												<div className="timeline-content text-right">
													<h4 className="title">IPSR SOLUTIONS</h4>
													<h5>Calicut,Kerala</h5>
													<p className="description">
													During my internship, I gained valuable experience working with PHP and had the opportunity to contribute to a live project, enhancing my skills significantly.
													</p>
												</div>
											</div>
											<div className="col-md-offset-1 col-md-5">
												<div className="timeline">
													<div className="experience-time">
													<span><i className="fa fa-circle" aria-hidden="true"></i></span>

													<h2>2018 - 2018</h2>
													<h3>Internship</h3>
													</div>
												</div>
											</div>
										</div>
									</div>
								</li>

							</ul>
						</div>
					</div>
			</div>

		</section>
    
    </>)
}

export default Experience;